<template>
    <div class="steps-listing_bottom_item stripped">
        <div class="row gx-0">
            <div class="col-md-3 col-sm-6">
                <div class="steps-listing_bottom_item_block">
                    <p> {{ title }} </p>
                </div>   
            </div>
            <template v-for="(data, index) in datas" :key="index">
                <div class="col-md-3 col-sm-6">
                    <div class="steps-listing_bottom_item_block">
                        <div class="icon">
                            <p v-if="data.agency_repair > 0">$ {{ data.agency_repair }}</p>
                            <span v-else :class="data.agency_repair ? 'icon-check' : 'icon-close_cross'"></span>
                        </div>
                    </div>   
                </div>
            </template>
            
            <div class="col-md-3 col-sm-6" v-if="datas.length == 2">
                <div class="steps-listing_bottom_item_block">
                
                </div>   
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        title: {
            required: true,
            type: String
        },
        datas: {
            required: true,
            type: Array
        }
    },

    setup() {
        
    },
})
</script>
